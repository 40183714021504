<script setup>
  import { Link } from '@inertiajs/vue3';
  import { computed } from 'vue';
  import { usePage } from '@inertiajs/vue3';

  const props = defineProps({
    href: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: '200%'
    }
  });

  const { url } = usePage();

  const textColorClass = computed(() => {
    if (url !== '/welcome-page' && url !== '/about') {
      return '!text-black';
    } else {
      return '!text-white !hover:text-white';
    }
  });
</script>

<template>
  <Link
    :href="href"
    class="justify-center rounded-md px-3 text-center text-lg text-gray-200 transition duration-200 hover:underline"
    :class="textColorClass"
    replace
  >
    <slot></slot>
  </Link>
</template>
