<script setup>
  import { ref } from 'vue';

  const props = defineProps({
    initialOpen: {
      type: Boolean,
      default: false
    },
    top: {
      type: String,
      default: 'auto'
    },
    right: {
      type: String,
      default: 'auto'
    },
    left: {
      type: String,
      default: 'auto'
    }
  });

  const isOpen = ref(props.initialOpen);
  const closeTimeoutId = ref(null);

  const openDropdown = () => {
    isOpen.value = true;
    clearTimeout(closeTimeoutId.value);
    closeTimeoutId.value = null;
  };

  const closeDropdown = () => {
    closeTimeoutId.value = setTimeout(() => {
      isOpen.value = false;
    }, 100);
  };

  const cancelCloseDropdown = () => {
    clearTimeout(closeTimeoutId.value);
    closeTimeoutId.value = null;
  };
</script>

<template>
  <div class="relative" @mouseover="openDropdown" @mouseleave="closeDropdown">
    <div class="hover:cursor-pointer"><slot name="button"></slot></div>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div
        v-if="isOpen"
        :style="{ top, right, left }"
        class="absolute z-10 flex max-h-72 flex-col items-center justify-center overflow-y-auto whitespace-nowrap rounded-md bg-gray-700 px-6 py-2 shadow-xl hover:text-gray-500"
      >
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>
